<template>
  <v-btn
      color="#D2E8FF"
      :min-width="48"
      @click="handleClick"
  >
    <v-icon
        color="primary"
    >
      {{icon}}
    </v-icon>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    defaultStatus: {
        type: Boolean,
        default: false
    },
    storeId: {
      type: String,
    },
    providerId: {
      type: String,
    },
    productId: {
      type: String,
    },
    onlyDelete: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {}
    },
  },
  data: () => ({
    status: false,
  }),
  computed: {
    layoutProviderId() {
      return this.$store.getters["base/layoutProviderId"];
    },
    isLogin() {
      return this.$store.getters["token/isLogin"];
    },
    hasStore() {
      return this.$store.getters["member/hasStore"];
    },
    icon() {
      if(this.onlyDelete) return 'ri-delete-bin-2-fill'
      if(this.status) return 'ri-heart-fill'
      return 'ri-heart-line'
    },
  },
  watch: {
    defaultStatus: {
      immediate: true,
      handler() {
        this.status = this.defaultStatus
      }
    },
  },
  methods: {
    async handleClick() {
      // 已登入但未註冊任何商家, 跳轉至商家註冊頁
      if (this.isLogin && !this.hasStore) {
        this.$router.replace({
          name: "store-register",
          params: {
            providerId: this.layoutProviderId,
          },
        })
        this.done = true
        return
      }

      if(this.onlyDelete) {
        await this.deleteFavorite()
        return
      }

      if(!this.status) {
        this.$root.gaLogEvent('商店_點擊_商品加入常用購物清單')
        await this.addFavorite()
        return
      }
      await this.deleteFavorite()
    },
    async addFavorite() {
      this.$store.dispatch('loading/active')
      try {
        await this.$api.collection.favoriteApi.update(this.storeId, this.providerId, this.productId)
        this.callback()
        this.status = true
        this.$snotify.success('加入成功')
      } catch(err) {
        console.error(err)
        this.$snotify.error('加入失敗')
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    async deleteFavorite() {
      this.$store.dispatch('loading/active')
      try {
        await this.$api.collection.favoriteApi.delete(this.storeId, this.providerId, this.productId)
        this.callback()
        this.status = false
        this.$snotify.success('已從清單中刪除')
      } catch(err) {
        console.error(err)
        this.$snotify.error('刪除失敗')
      } finally {
        this.$store.dispatch('loading/close')
      }
    },

  },

}
</script>

